// app/javascript/controllers/song_select_controller.js
import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    options: Array,
    placeholder: String,
    createOnBlur: Boolean,
  };

  connect() {
    this.tomSelect = new TomSelect(this.element, {
      create: true,
      createOnBlur: this.hasCreateOnBlurValue ? this.createOnBlurValue : false,
      placeholder: this.hasPlaceholderValue
        ? this.placeholderValue
        : "Select...",
      options: this.optionsValue,
      valueField: "value",
      labelField: "text",
      searchField: "text",
      render: {
        option: function (data, escape) {
          return `<div class="py-2 px-3 hover:bg-gray-100">${escape(
            data.text
          )}</div>`;
        },
        item: function (data, escape) {
          return `<div>${escape(data.text)}</div>`;
        },
      },
    });

    this.element.closest("form")?.addEventListener("reset", () => {
      this.tomSelect.clear();
    });
  }

  disconnect() {
    if (this.tomSelect) {
      this.tomSelect.destroy();
    }
  }
}
