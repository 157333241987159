// app/javascript/controllers/set_list_songs_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "destination", "form"];
  static values = {
    setlistId: String,
  };

  connect() {}

  addOrRemoveSong(event) {
    event.preventDefault();

    const songIds = Array.from(
      this.destinationTarget.querySelectorAll("li")
    ).map((li) => {
      return li.dataset.id;
    });
    const songId = event.target.closest("li").dataset.id;
    if (!songIds.includes(songId)) {
      songIds.push(songId);
    } else {
      // If the song ID is already in the array, remove it
      event.target.closest("li").classList.add("bg-blue-100");
      songIds.splice(songIds.indexOf(songId), 1);
    }
    // Check if the song ID is already in the array to prevent duplicates

    const searchForm = document.querySelector(
      'form[data-async-form-target="form"]'
    );
    let searchParams = {};

    // If the search form exists, get its data
    if (searchForm) {
      const formData = new FormData(searchForm);
      formData.forEach((value, key) => {
        searchParams[key] = value;
      });
    }

    // Get CSRF token
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content");

    fetch(this.formTarget.action, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/html", // Request HTML
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        set_list: {
          song_ids: songIds,
          ...searchParams,
        },
      }),
    })
      .then((response) => response.text())
      .then((html) => {
        // Create a temporary container to parse the HTML
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        // Find the destination div in the returned HTML
        const newDestination = doc.querySelector(
          '[data-set-list-entries-target="destination"]'
        );

        if (newDestination) {
          // Replace just the contents of the destination div
          this.destinationTarget.innerHTML = newDestination.innerHTML;
        } else {
          // console.error(
          //   "Could not find destination element in the returned HTML"
          // );
        }

        this.sourceTarget
          .querySelector(`#song_${songId}`)
          .classList.add("selected");
      })
      .catch((error) => {
        // console.error("Error:", error);
        // Optionally uncheck the checkbox if there was an error
        event.target.checked = false;
      })
      .finally(() => {
        // After a delay, allow processing again
        setTimeout(() => {
          this.isProcessing = false;
        }, 300);
      });
  }
}
