// app/javascript/controllers/async_form_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];
  static values = {
    targetElementSelector: { type: String, default: "" },
    loadingClass: { type: String, default: "opacity-50 pointer-events-none" },
    debounce: { type: Number, default: 300 },
  };

  connect() {}

  submit(event) {
    event.preventDefault();

    // Get the form
    const form = this.formTarget;

    // Add loading state
    if (this.loadingClassValue) {
      const classes = this.loadingClassValue.split(" ");
      classes.forEach((className) => {
        if (className) form.classList.add(className.trim());
      });
    }

    // Build the URL with form data
    const url = form.action + "?" + new URLSearchParams(new FormData(form));

    // Get CSRF token
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content");

    // Perform the AJAX request
    fetch(url, {
      headers: {
        Accept: "text/html",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        // Parse the HTML response
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        // Find the element to update in the current document
        const container = this.formTarget.closest("[data-controller]");
        const targetElement = this.targetElementSelectorValue
          ? container.querySelector(this.targetElementSelectorValue)
          : null;

        if (!targetElement) {
          console.error(
            `Element to update '${this.targetElementSelectorValue}' not found`
          );
          return;
        }

        // Find the corresponding element in the response
        const sourceElement = this.targetElementSelectorValue
          ? doc.querySelector(this.targetElementSelectorValue)
          : null;

        if (sourceElement) {
          // Replace the content
          targetElement.innerHTML = sourceElement.innerHTML;
        } else {
          console.error(
            `Element '${this.targetElementSelectorValue}' not found in response`
          );
        }

        // Update the URL in the browser history without a page reload
        // window.history.pushState({}, "", url);
      })
      .catch((error) => {
        // console.error("Error during fetch:", error);
      })
      .finally(() => {
        // Remove loading state
        // Remove loading state
        if (this.loadingClassValue) {
          const classes = this.loadingClassValue.split(" ");
          classes.forEach((className) => {
            if (className) form.classList.remove(className.trim());
          });
        }
      });
  }

  // Auto-submit functionality with debounce
  autoSubmit(event) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, this.debounceValue);
  }

  // Submit immediately on change (for select elements)
  submitOnChange(event) {
    this.formTarget.requestSubmit();
  }
}
