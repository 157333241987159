// app/javascript/controllers/tag_select_controller.js
import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    options: Array,
    placeholder: String,
    maxItems: Number,
    createOnBlur: Boolean,
  };

  connect() {
    const originalName = this.element.getAttribute("name");

    // Simple render functions that let styles live in CSS
    const renderItem = (data, escape) => {
      const div = document.createElement("div");
      const colors = ["red", "blue", "green", "yellow", "purple", "pink"];
      const colorIndex =
        data.text.split("").reduce((a, b) => a + b.charCodeAt(0), 0) % 6;
      div.className = `ts-tag tag-${colors[colorIndex]} item`;
      div.textContent = data.text;
      return div;
    };

    const renderOption = (data, escape) => {
      const div = document.createElement("div");
      div.className = "ts-option";
      div.textContent = data.text;
      return div;
    };

    // Initialize options
    const selectedOptions = Array.from(
      this.element.querySelectorAll("option[selected]")
    ).map((option) => ({
      value: option.value,
      text: option.textContent,
    }));

    const availableOptions = this.hasOptionsValue
      ? this.optionsValue.map((option) => ({
          value: option,
          text: option,
        }))
      : [];

    const allOptions = [...selectedOptions, ...availableOptions];
    const uniqueOptions = Array.from(
      new Set(allOptions.map((opt) => opt.value))
    ).map((value) => allOptions.find((opt) => opt.value === value));

    this.tomSelect = new TomSelect(this.element, {
      create: true,
      createOnBlur: this.hasCreateOnBlurValue ? this.createOnBlurValue : false,
      maxItems: this.hasMaxItemsValue ? this.maxItemsValue : null,
      placeholder: this.hasPlaceholderValue
        ? this.placeholderValue
        : "Select...",
      options: uniqueOptions,
      items: selectedOptions.map((opt) => opt.value),
      delimiter: ",",
      render: {
        item: renderItem,
        option: renderOption,
      },
      plugins: {
        remove_button: {
          title: "Remove",
        },
      },

      onChange: (value) => {
        const hiddenInput = this.tomSelect.wrapper.querySelector(
          'input[type="hidden"]'
        );
        if (hiddenInput) {
          hiddenInput.setAttribute("name", originalName);
        }
        this.element.dispatchEvent(new Event("change", { bubbles: true }));
      },
    });

    // Handle form reset
    this.element.closest("form")?.addEventListener("reset", () => {
      this.tomSelect.clear();
    });
  }

  disconnect() {
    if (this.tomSelect) {
      this.tomSelect.destroy();
    }
  }
}
