import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["sortableList"];
  static values = {
    url: String,
    animation: { type: Number, default: 150 },
    handle: { type: String, default: "" },
    ghostClass: { type: String, default: "sortable-ghost" },
    chosenClass: { type: String, default: "sortable-chosen" },
    dragClass: { type: String, default: "sortable-drag" },
    disabled: { type: Boolean, default: false },
  };

  connect() {
    this.initializeSortable();
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy();
    }
  }

  initializeSortable() {
    const options = {
      animation: this.animationValue,
      handle: this.handleValue || null,
      ghostClass: this.ghostClassValue,
      chosenClass: this.chosenClassValue,
      dragClass: this.dragClassValue,
      disabled: this.disabledValue,
      onStart: this.onDragStart.bind(this),
      onEnd: this.onDragEnd.bind(this),
      onUpdate: this.onUpdate.bind(this),
    };

    this.sortable = new Sortable(this.sortableListTarget, options);

    // Store initial order for comparison
    this.originalOrder = this.getCurrentOrder();
  }

  onDragStart(evt) {
    // Add a data attribute or class to indicate dragging state
    this.element.setAttribute("data-sorting", "true");
    // Dispatch custom event that can be listened to
    // this.dispatch("sortStart", {
    //   detail: { item: evt.item, oldIndex: evt.oldIndex },
    // });
  }

  onDragEnd(evt) {
    // Remove dragging state indicator
    this.element.removeAttribute("data-sorting");
    // Dispatch custom event
    this.dispatch("sortEnd", {
      detail: { item: evt.item, newIndex: evt.newIndex },
    });
  }

  onUpdate(evt) {
    const newOrder = this.getCurrentOrder();

    // Only send request if order actually changed
    if (JSON.stringify(this.originalOrder) !== JSON.stringify(newOrder)) {
      this.updateServer(newOrder);
    }
  }

  getCurrentOrder() {
    return this.sortable.toArray();
  }

  updateServer(order) {
    // Show loading indicator
    this.element.classList.add("is-updating");

    fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/html",
        "X-CSRF-Token":
          document.querySelector('meta[name="csrf-token"]')?.content || "",
      },
      body: JSON.stringify({ set_list: { song_ids: order } }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Server responded with ${response.status}`);
        }
        this.showSuccessFeedback();
        // Update the stored original order after successful update
        this.originalOrder = order;
        // this.dispatch("updateSuccess", { detail: { order } });
      })
      .catch((error) => {
        // console.error("Error updating order:", error);
        // Revert to original order in UI
        // this.revertToOriginalOrder();
        // this.dispatch("updateError", { detail: { error } });
      })
      .finally(() => {
        this.element.classList.remove("is-updating");
      });
  }

  showSuccessFeedback() {
    // console.log("Success feedback triggered");
    // Flash the list itself
    this.sortableListTarget.classList.add("sortable-success-flash");

    setTimeout(() => {
      this.sortableListTarget.classList.remove("sortable-success-flash");
    }, this.feedbackDurationValue);
  }
  // revertToOriginalOrder() {
  //   This would need implementation specific to your DOM structure
  //   One approach is to force re-render from server or restore elements from a cache
  // }

  // Action methods for external control
  // enable() {
  //   if (this.sortable) {
  //     this.sortable.option("disabled", false);
  //     this.disabledValue = false;
  //   }
  // }

  // disable() {
  //   if (this.sortable) {
  //     this.sortable.option("disabled", true);
  //     this.disabledValue = true;
  //   }
  // }

  // refresh() {
  //   if (this.sortable) {
  //     this.sortable.destroy();
  //   }
  //   this.initializeSortable();
  // }
}
