import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message", "recordingInterface"];

  connect() {
    // Check permissions when controller connects
    this.checkMicrophonePermission();
  }

  // Check permission status using Permissions API
  async checkMicrophonePermission() {
    if (!navigator.permissions || !navigator.permissions.query) {
      // Permissions API not available, fallback to direct request
      this.dispatch("fallback");
      return;
    }

    try {
      const permissionStatus = await navigator.permissions.query({
        name: "microphone",
      });

      // Handle current permission state
      this.handlePermissionStatus(permissionStatus);

      // Listen for permission changes
      permissionStatus.onchange = () => {
        this.handlePermissionStatus(permissionStatus);
      };
    } catch (error) {
      this.dispatch("fallback");
    }
  }

  // Handle different permission states
  handlePermissionStatus(status) {
    if (status.state === "granted") {
      this.hideMessage();
      this.dispatch("granted");
    } else if (status.state === "denied") {
      this.showMessage();
      this.dispatch("denied");
    } else {
      // For 'prompt' state
      this.dispatch("prompt");
    }
  }

  // Display permission denied message
  showMessage() {
    if (this.hasMessageTarget) {
      this.recordingInterfaceTarget.classList.add("hidden");
      this.messageTarget.classList.remove("hidden");
    } else {
      // Create a simple message if no target exists
      const message = document.createElement("div");
      message.className = "microphone-permission-message";
      message.innerHTML = `
        <p>Microphone access is required for recording.</p>
        <button class="retry-button">Allow Microphone Access</button>
      `;

      this.element.insertAdjacentElement("afterend", message);

      // Add click handler to the button
      const retryButton = message.querySelector(".retry-button");
      retryButton.addEventListener("click", this.requestPermission.bind(this));
    }
  }

  // Hide permission message
  hideMessage() {
    if (this.hasMessageTarget) {
      this.recordingInterfaceTarget.classList.remove("hidden");
      this.messageTarget.classList.add("hidden");
    } else {
      // Remove any dynamically created messages
      const message = document.querySelector(".microphone-permission-message");
      if (message) {
        message.remove();
      }
    }
  }

  // Request microphone permission - triggered by UI
  requestPermission() {
    // Hide any messages while we request
    this.hideMessage();

    // Simply attempt to get user media to trigger browser permission prompt
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          this.dispatch("granted");
        })
        .catch(() => {
          this.dispatch("denied");
        });
    }
  }

  // Simple event dispatcher
  dispatch(eventName) {
    const event = new CustomEvent(`microphone-permission:${eventName}`, {
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }
}
